import { Button } from "@/components/ui/button";

type PaymentButtonProps = {
  amount: number;
  currency?: string;
  compensationId: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

export default function PaymentButton(props: PaymentButtonProps) {
  const {
    amount,
    currency = "PLN",
    compensationId,
    disabled,
    children,
  } = props;

  return (
    <form
      action="https://vprevfbwpggljbrnmscq.supabase.co/functions/v1/payments"
      method="POST"
    >
      <input type="hidden" name="amount" value={amount} />
      <input type="hidden" name="currency" value={currency} />
      <input type="hidden" name="compensationId" value={compensationId} />
      <Button
        size="sm"
        variant="link"
        type="submit"
        disabled={disabled}
        className="px-2"
      >
        {children}
      </Button>
    </form>
  );
}
