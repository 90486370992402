export const FEE = 7;

export const calculateRefund = (amount_due: string) => {
  // example structure of amount_due: "12.34 PLN"
  const amount = amount_due
    .replace(",", ".")
    .replace(/zł|pln/gi, "")
    .trim();
  return parseFloat(amount + (FEE + 1 + parseFloat(amount) * 0.015).toFixed(2));
};

export const calculateTotalFee = (amount_due: string) => {
  // example structure of amount_due: "12.34 PLN"
  const amount = amount_due
    .replace(",", ".")
    .replace(/zł|pln/gi, "")
    .trim();
  return parseFloat((FEE + 1 + parseFloat(amount) * 0.015).toFixed(2));
};
