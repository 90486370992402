import { base64ToFile } from "@/utils/base64";
import { logger } from "../logger";
import { supabase } from "./supabase";

function saveFile(blob: Blob, filename: string) {
  // @ts-expect-error - Windows only
  if (window?.navigator?.msSaveOrOpenBlob) {
    // @ts-expect-error - Windows only
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(
      new Blob([blob], { type: blob.type }),
    );
    a.href = url;
    a.download = filename.match(/(.*)(-)/i)?.[1] || filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
}

export const downloadSupabaseFile = async (
  bucket: string,
  fileName?: string | null,
  save: boolean = true,
) => {
  if (!fileName) {
    logger.error("No file name provided");
    return { data: null, url: null, error: "No file name provided" };
  }
  const { data, error } = await supabase.storage
    .from(bucket)
    .download(fileName);

  if (save && data) {
    saveFile(data, fileName);
  }

  const url = data
    ? window.URL.createObjectURL(new Blob([data], { type: data.type }))
    : null;

  if (error) {
    logger.error("Failed to download file", { error, url });
  } else {
    logger.metrics("file_download");
  }

  return { data, error, url };
};

export const downloadBlob = async (
  blob: string,
  fileName = "file",
) => {
  if (!blob) {
    logger.error("No blob provided");
    return { data: null, url: null, error: "No blob provided" };
  }

  const file = await base64ToFile(blob, "file");

  saveFile(file, fileName);

  return { blob };
};

export const getSupabaseFileUrl = async (
  bucketName: string,
  fileName: string,
  expiresIn = 60,
) => {
  return await supabase.storage
    .from(bucketName)
    .createSignedUrl(fileName, expiresIn);
};
