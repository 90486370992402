import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { compensationTemplate } from "./template";
import { calculateRefund, FEE } from "./calculations";
import { logger } from "../logger";
import { useCreateCompensation } from "../supabase/crud-helpers/compensations";
import {
  useReadLoggedUserProfile,
  useUpdateUserProfile,
} from "../supabase/crud-helpers/user";
import { Database } from "../supabase/schema";
import { uploadFile } from "../supabase/upload-file";
import { acceptedFileTypes } from "./accepted-file-types";
import { readTicket } from "../ai/read-ticket.new";
import { fileToBase64 } from "@/utils/base64";

export const useCreateCompensationItem = () => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const { user, profile } = useReadLoggedUserProfile();
  const { insertCompensation } = useCreateCompensation();
  const { updateProfile } = useUpdateUserProfile();

  const createCompensation = async (e: React.FormEvent<HTMLFormElement>) => {
    logger.metrics("file_upload");
    let fail = false;
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const file = formData.get("file") as File;
    const delay_1h_or_more = Boolean(formData.get("delay_1h_or_more"));
    const day = new Date().toLocaleDateString("pl").replaceAll(".", "_");
    const time = new Date().toLocaleTimeString("pl");
    const isImageFile = file?.type.startsWith("image");
    if (isImageFile) {
      const base64Img = await fileToBase64(file);
      formData.delete("file");
      formData.append("base64Img", base64Img);
    } else {
      formData.append("pdf", file);
    }

    if (!acceptedFileTypes.includes(file?.type)) {
      logger.error("Invalid file type", { file });
      toast({
        title: "Błąd",
        description: ("Nieprawidłowy typ pliku"),
      });
      setLoading(false);
      return;
    }

    if (!file || !user?.id) {
      logger.error("No file or user found", { file, user });
      toast({
        title: "Błąd",
        description: ("Nieprawidłowe dane formularza"),
      });
      setLoading(false);
      return;
    }

    e?.currentTarget?.reset();

    toast({
      title: "Info",
      description: `Rozpoczęto przetwarzanie danych biletu z ${
        (formData.get("file") as File)?.type.startsWith("image")
          ? "obrazu"
          : "pdf"
      }...`,
    });
    const uploadName = `${day}/${time}_${user.id}_${file.name}`;
    console.log("uploadName", uploadName);
    const reply = await readTicket(formData);
    // const reply = isImageFile
    //   ? await readImageTicket(formData)
    //   : await readPdfTicket(formData);

    console.log("reply", reply);

    const { is_ticket, ...ticketData } = reply;

    if (!is_ticket) {
      toast({
        title: "Błąd",
        description:
          ("Plik nie wydaje się poprawny. Nie udało się odczytać danych biletu"),
      });
      setLoading(false);
      updateProfile({
        offences_committed: (profile.offences_committed || 0) + 1,
      });
      return;
    }

    const { upload, uploadError } = await uploadFile(
      "tickets",
      uploadName,
      file,
    );
    if (uploadError) {
      logger.error("Failed to upload file", uploadError);
      toast({
        title: "Błąd",
        description: ("Nie udało się przesłać pliku"),
      });
      setLoading(false);
      return;
    }

    const newCompensation = {
      ...compensationTemplate,
      ...ticketData,
      ticket_file_type: file.type,
      delay_1h_or_more: delay_1h_or_more,
      link_to_ticket: upload?.path,
      status: "draft" as const,
      requested_refund: calculateRefund(
        ticketData.ticket_details__total_amount || "0.00 PLN",
      ),
      received_refund: 0,
      processing_fee: FEE,
      additional_details: profile.full_name?.trim() !==
          ticketData.ticket_details__passenger_full_name?.trim()
        ? "Imię i nazwisko na bilecie nie zgadza się z imieniem i nazwiskiem w profilu!"
        : "",
    } as Omit<
      Database["public"]["Tables"]["compensations"]["Row"],
      "user_id"
    >;

    const result = await insertCompensation(newCompensation)
      .catch((error) => {
        fail = true;
        logger.error("Error while creating compensation", error);
        toast({
          title: "Błąd",
          description: "Nie udało się dodać odszkodowania.",
        });
        toast({
          title: "Błąd",
          description: error.message,
        });
      })
      .finally(() => {
        document.getElementById("cancel-add")?.click();
        setLoading(false);
        if (!fail) {
          toast({
            title: "Sukces",
            description: "Odszkodowanie zostało dodane.",
          });
          logger.metrics("create_compensation");
        }
      });

    if (!result || fail) {
      logger.error("Failed to create compensation");
      toast({
        title: "Błąd",
        description: "Nie udało się utworzyć rekordu odszkodowania",
      });
    }
  };

  return {
    loading,
    createCompensation,
  };
};
