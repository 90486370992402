import {
  useQuery,
  useUpdateMutation,
} from "@supabase-cache-helpers/postgrest-swr";
import { userReadableProfileFieldsString } from "../../user/fields";
import { useActiveSession } from "../session";
import { supabase } from "../supabase";
import { logger } from "@/lib/logger";
import { Database } from "../schema";

export const useReadLoggedUserProfile = () => {
  const { session } = useActiveSession();
  const { data, error } = useQuery(
    session?.user?.id
      ? supabase
        .from("profiles")
        .select(userReadableProfileFieldsString)
        .eq("id", session?.user?.id || "user unknown")
      : null,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (error) {
    logger.error("useReadLoggedUserProfile", { session, data, error });
    throw new Error("Nie przygotować odczytu profilu.");
  }

  return {
    user: session?.user,
    profile: data?.[0] as Database["public"]["Tables"]["profiles"]["Row"],
  };
};

export const useUpdateUserProfile = () => {
  const { user } = useReadLoggedUserProfile();
  const { trigger, error } = useUpdateMutation(
    // @ts-expect-error - library out of date, need to investigate options
    supabase.from("profiles"),
    ["id"],
    userReadableProfileFieldsString,
  );

  if (error) {
    logger.error("useUpdateUserProfile", { error });
  }

  return {
    error,
    updateProfile: (
      update: Partial<
        Omit<Database["public"]["Tables"]["profiles"]["Row"], "id">
      >,
    ) => trigger({ id: user?.id, ...update }),
  };
};
