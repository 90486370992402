import { Button } from "@/components/ui/button";
import { useActiveSession } from "@/lib/supabase/session";
import { supabase } from "@/lib/supabase/supabase";

type MailingButtonProps = {
  compensationId: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

export default function MailingButton(props: MailingButtonProps) {
  const { compensationId, disabled, children } = props;
  const user = useActiveSession();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await supabase.functions
      .invoke(
        `mailing?compensation_id=${compensationId}&email=${user?.session?.user.email}`,
        {
          method: "POST",
        }
      )
      .then(() => {
        console.log("Sent email");
      })
      .catch((error) => {
        console.error("Failed to send email", error);
      })
      .finally(() => {
        console.log("Reloading page");
        window.location.reload();
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="hidden" name="compensationId" value={compensationId} />
      <Button
        size="sm"
        variant="link"
        type="submit"
        disabled={disabled}
        className="px-2"
      >
        {children}
      </Button>
    </form>
  );
}
