import { useEffect } from "react";
import { useReadCompensations } from "../supabase/crud-helpers/compensations";
import { useLocation } from "react-router-dom";
import { supabase } from "../supabase/supabase";

export function useBackgroundActionUpdateStatus() {
  const location = useLocation();
  const { data, error } = useReadCompensations();

  const queryParamsSessionId = new URLSearchParams(location.search).get(
    "session_id",
  );
  const compensationId = new URLSearchParams(location.search).get(
    "compensation_id",
  );

  const compensation = data?.find((c) => c.id === compensationId);

  useEffect(() => {
    if (
      compensationId &&
      compensation?.status === "signed" &&
      location.search.includes("status=success") &&
      queryParamsSessionId
    ) {
      console.info({ queryParamsSessionId, status: compensation?.status });
      supabase.functions.invoke(
        `fullfilment?session_id=${queryParamsSessionId}&compensation_id=${compensationId}`,
        {
          method: "POST",
        },
      )
        .then(() => {
          window.location.href = "/compensations";
        });
    }
  }, [
    compensation?.status,
    compensationId,
    location.search,
    queryParamsSessionId,
  ]);

  return { data, error };
}
