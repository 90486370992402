import { Session } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import { supabase } from "./supabase";
import { logger } from "../logger";

export async function getActiveSession() {
  const {
    data: { session },
  } = await supabase.auth.getSession();

  return session?.user;
}

export const useActiveSession = () => {
  const [session, setSession] = useState<Session | null>();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      // logger.metrics("signIn");
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return { session: session || null };
};

export const signOut = async () => {
  logger.metrics("signOut");
  return supabase.auth.signOut();
};
